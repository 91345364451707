import React, { Fragment } from "react"


export default () => (
  <Fragment>
    Welcome to the Kanji Kame Development Blog :)
    <br/>
    Got a suggestion? Let us know at: <br/> kanji-kame-support@googlegroups.com
  </Fragment>
)
